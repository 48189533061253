<template>
    <b-card no-body class="mb-4 p-4">
        <h3>Role Detail</h3>
        <div class="mb-4">
            <!-- Message -->
            <div class="border-bottom d-flex justify-content-between py-2">
                <h6 class="message-title text-dark mb-0 mt-1">Description</h6>
                <span class="mail-desc d-block text-secondary">{{ role.description }}</span>
            </div>
            <div class="border-bottom d-flex justify-content-between py-2">
                <h6 class="message-title text-dark mb-0 mt-1">Created By</h6>
                <span class="mail-desc d-block text-secondary">{{ role.createdBy }}</span>
            </div>
            <div class="border-bottom d-flex justify-content-between py-2">
                <h6 class="message-title text-dark mb-0 mt-1">Creation Date</h6>
                <span class="mail-desc d-block text-secondary">{{ $formatDate(role.createDate) }}</span>
            </div>
            <div class="border-bottom d-flex justify-content-between py-2">
                <h6 class="message-title text-dark mb-0 mt-1">Modified By</h6>
                <span class="mail-desc d-block text-secondary">{{ role.modifiedBy }}</span>
            </div>
            <div class="border-bottom d-flex justify-content-between py-2">
                <h6 class="message-title text-dark mb-0 mt-1">Modified Date</h6>
                <span class="mail-desc d-block text-secondary">{{ $formatDate(role.modifiedDate) }}</span>
            </div>
        </div>
        <b-form-group
            v-slot="{ ariaDescribedby }"
            label="Modules & Permissions"
            label-class="fw-medium mb-3"
            label-for="roles"
        >
            <div v-for="item in authorizations" :key="item.id" class="mb-4">
                <h4>{{ item.name }}</h4>
                <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 4px">
                    <b-form-checkbox
                        v-for="option in item.authorizations"
                        :key="option.id"
                        :value="option.id"
                        v-model="permissions"
                        name="screens"
                        :aria-describedby="ariaDescribedby"
                    >
                        {{ option.description }}
                    </b-form-checkbox>
                </div>
            </div>
        </b-form-group>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            role: {},
            permissions: [],
        };
    },
    computed: {
        ...mapGetters({
            authorizations: 'authorizations/getAuthorizations',
        }),
    },
    methods: {
        async getData() {
            const id = this.$route.params.id;
            await this.$store.dispatch('authorizations/get_allAuthorizations');
            const roleData = await this.$store.dispatch('roles/get_role', id);
            this.role = roleData;
            this.permissions = roleData.authorizations.map((auth) => auth.id);
        },
    },
    created() {
        this.getData();
    },
};
</script>
